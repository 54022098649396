/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"

import { withTranslation } from "react-i18next"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"

const Users = () => {
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Name",
      field: "Name",
      sort: "asc",
    },

    {
      label: "Email",
      field: "email",
      sort: "asc",
    },
    {
      label: "Phone Number",
      field: "phone",
      sort: "asc",
    },
    {
      label: "Address",
      field: "address",
      sort: "asc",
    },
    {
      label: "",
      field: "actions",
    },
  ]
  const [reload, setReload] = useState(false)
  const [users, setUsers] = useState([])
  // document.title = "Users"
  useEffect(() => {
    axios
      .get("admins/users", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data)
        setUsers(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [reload])

  const handleBan = (id) => {
    let confirm = window.confirm(
      "You are about ban/unban this user, do you wish to continue?"
    )
    if (confirm) {
      axios
        .post(
          `admins/banUser/${id}`,
          {},
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setReload(!reload)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this user, do you wish to continue?"
    )
    if (confirm) {
      axios
        .delete(`admins/users/delete/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          setReload(!reload)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Users</h5>
              </Col>
            </Row>
          </div>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Users List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: users.map((one) => {
                        return {
                          ...one,
                          phone: one.phone,
                          Name: one.name + " " + one.lastName,
                          address:
                            one.address && one.address[0]
                              ? one.address[0].apartment +
                                ", " +
                                one.address[0].street +
                                ", " +
                                one.address[0].city +
                                ", " +
                                one.address[0].country +
                                ", " +
                                one.address[0].zipCode
                              : "",
                          actions: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              <Col md={6} xs={12}>
                                <Link
                                  to="#"
                                  className={
                                    one.banned
                                      ? "btn btn-success btn-sm"
                                      : "btn btn-danger btn-sm"
                                  }
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleBan(one._id)
                                  }}
                                >
                                  {one.banned ? (
                                    <i className="fas fa-check"></i>
                                  ) : (
                                    <i className="fas fa-ban"></i>
                                  )}
                                </Link>
                              </Col>
                              <Col md={6} xs={12}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleDelete(one._id)
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Users)
