import PropTypes from "prop-types"
import { useState } from "react"
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { useFormik } from "formik"
import axios from "axios/axios"
import { useParams } from "react-router-dom"

const AddEditTutorial = ({
  edit,
  setEdit,
  setMenu,
  color,
  setColor,
  setReload,
  errorMsg,
  setErrorMsg,
  initialColorValues,
}) => {
  const { id } = useParams()
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  // Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: color,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = (values) => {
    setErrorMsg("")
    setLoadingSubmit(true)
    if (edit) {
      axios
        .patch(
          `country/updateCity/${values._id}`,
          {
            name: values.name,
            arName: values.arName,
            price: parseInt(values.price),
            country: id,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((_) => {
          setLoadingSubmit(false)
          setReload((prev) => !prev)
          setColor(initialColorValues)
          setEdit(false)
          setMenu(false)
        })
        .catch((e) => {
          setErrorMsg(e.response.data?.message || "Something went wrong")
          setLoadingSubmit(false)
        })
    } else {
      axios
        .post(
          `country/addCity`,
          {
            name: values.name,
            arName: values.arName,
            price: parseInt(values.price),
            country: id,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((_) => {
          setLoadingSubmit(false)
          setReload((prev) => !prev)
          setColor(initialColorValues)
        })
        .catch((e) => {
          setErrorMsg(e.response.data?.message || "Something went wrong")
          setLoadingSubmit(false)
        })
    }
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row style={{ width: "100%" }}>
          {errorMsg && (
            <Alert className="mt-3" color="danger">
              {errorMsg}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required={true}
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={color.name}
                onChange={(e) => {
                  setColor((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                  validation.handleChange(e)
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                {"Name (AR)"}
              </Label>
              <Input
                required={true}
                id="arName"
                className="form-control"
                type="text"
                placeholder="Name (AR)"
                value={color.arName}
                onChange={(e) => {
                  setColor((prev) => ({
                    ...prev,
                    arName: e.target.value,
                  }))
                  validation.handleChange(e)
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="price" style={{ color: "#5B626B" }}>
                {"price"}
              </Label>
              <Input
                required={true}
                id="price"
                className="form-control"
                type="number"
                placeholder="price"
                value={color.price}
                onChange={(e) => {
                  setColor((prev) => ({
                    ...prev,
                    price: e.target.value,
                  }))
                  validation.handleChange(e)
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="col-12">
          <button className="btn btn-primary" type={"submit"}>
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}

AddEditTutorial.propTypes = {
  color: PropTypes.any,
  setColor: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditTutorial
