/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"
import AddEditAdmin from "./AddEditAdmin.js"

//i18n
import { withTranslation } from "react-i18next"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import Pages404 from "pages/Utility/pages-404.js"
import Pages401 from "pages/Utility/pages-401.js"

const Admins = () => {
    const columns = [
        {
            label: "Name",
            field: "name",
            sort: "asc",
        },
        {
            label: "Email",
            field: "email",
            sort: "asc",
        },
        {
            label: "Super",
            field: "super",
        },
        {
            label: "",
            field: "actions",
        },
    ]
    const adminInitial = {
        email: "",
        password: "",
        name: "",
    }
    const [menu, setMenu] = useState(false)
    const [reload, setReload] = useState(false)
    const [admins, setAdmins] = useState([])
    const [admin, setAdmin] = useState(adminInitial)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    // document.title = "Admins"

    useEffect(() => {
        setError(false)
        setLoading(true)
        axios
            .get("admins/admins", {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setError(false)
                setLoading(false)
                setAdmins(
                    res.data.admins.map((one) => {
                        return {
                            _id: one._id,
                            email: one.email,
                            name: one.name,
                            super: one.adminRole == "superAdmin",
                        }
                    })
                )
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                setError(true)
            })
    }, [reload])

    const handleDelete = (id) => {
        let confirm = window.confirm(
            "You are about delete this admin, do you wish to continue?"
        )
        if (confirm) {
            axios
                .delete(`admins/deleteAdmin/${id}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    console.log(res)
                    setReload(!reload)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }
    return loading ? (
        <React.Fragment>
            <div className="page-content">
                <Container
                    fluid
                    style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner size={"lg"} color="primary" />
                </Container>
            </div>
        </React.Fragment>
    ) : error ? (
        <Pages401 />
    ) : (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h5 className="page-title">Admins</h5>
                            </Col>
                        </Row>
                    </div>

                    <Row className="mb-4">
                        <Col>
                            <Accordion
                                expanded={menu}
                                style={{ boxShadow: "none" }}
                            >
                                <AccordionSummary
                                    style={{ backgroundColor: "#E9ECEF" }}
                                    onClick={() => {
                                        setMenu(!menu)
                                    }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h6
                                        style={{
                                            fontWeight: "bold",
                                            color: "#5B626B",
                                        }}
                                    >
                                        Add Admin
                                    </h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AddEditAdmin
                                        admin={admin}
                                        setAdmin={setAdmin}
                                        setReload={setReload}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h6 style={{ fontWeight: "bold" }}>
                                        Admins List
                                    </h6>
                                </CardHeader>
                                <CardBody>
                                    <MDBDataTable
                                        hover
                                        responsive
                                        noBottomColumns
                                        data={{
                                            columns: columns,
                                            rows: admins.map((one) => {
                                                return {
                                                    ...one,
                                                    super: one.super ? (
                                                        <i
                                                            className="ion ion-md-checkmark fs-4"
                                                            style={{
                                                                color: "green",
                                                            }}
                                                        ></i>
                                                    ) : (
                                                        <i
                                                            className="ion ion-md-close fs-4"
                                                            style={{
                                                                color: "#ec4561",
                                                            }}
                                                        ></i>
                                                    ),
                                                    actions: (
                                                        <Link
                                                            to="#"
                                                            className="btn btn-danger btn-sm"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                handleDelete(
                                                                    one._id
                                                                )
                                                            }}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </Link>
                                                    ),
                                                }
                                            }),
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

Admins.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(Admins)
