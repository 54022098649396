import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Alert,
} from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"
import AddEditTutorial from "./AddEditCountry"

//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
// import Pages404 from "pages/Utility/pages-404.js"
import Pages401 from "pages/Utility/pages-401.js"

const Tutorials = () => {
  // Table columns
  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Name (AR)",
      field: "arName",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ]

  // Initial color values
  const initialColorValues = {
    name: "",
    arName: "",
  }

  // States
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [colors, setColors] = useState([])
  const [color, setColor] = useState(initialColorValues)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [addEditErrorMsg, setAddEditErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    setError(false)
    setLoading(true)
    setErrorMsg("")
    axios
      .get("country/getCountries", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setError(false)
        setLoading(false)
        setColors(
          res.data.data?.map((one) => {
            return {
              _id: one._id,
              name: one.name,
              arName: one.arName,
            }
          })
        )
      })
      .catch((e) => {
        setLoading(false)
        setErrorMsg(e.response.data?.message)
      })
  }, [reload])

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this country, do you wish to continue?"
    )
    if (confirm) {
      axios
        .delete(`zones/countries/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setReload(!reload)
        })
        .catch((e) => {
          setLoading(false)
          setErrorMsg(e.response.data?.message)
        })
    }
  }

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container
          fluid
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} color="primary" />
        </Container>
      </div>
    </React.Fragment>
  ) : error ? (
    <Pages401 />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Page title */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Countries</h5>
              </Col>
            </Row>
          </div>
          {/* Add Edit Tutorial */}
          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {edit ? "Edit Country" : "Add Country"}
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditTutorial
                    edit={edit}
                    setEdit={setEdit}
                    setMenu={setMenu}
                    initialColorValues={initialColorValues}
                    color={color}
                    setColor={setColor}
                    setReload={setReload}
                    errorMsg={addEditErrorMsg}
                    setErrorMsg={setAddEditErrorMsg}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
          {/* Colors list */}
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Countries List</h6>
                </CardHeader>
                <CardBody>
                  {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: colors.map((one) => {
                        return {
                          ...one,
                          actions: (
                            <>
                              <Link
                                to={`/countries/${one._id}/governorates`}
                                className="btn btn-info btn-sm me-2"
                              >
                                <i className="fas fa-info"></i>
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setError("")
                                  setAddEditErrorMsg("")
                                  if (edit) {
                                    setMenu(false)
                                    setEdit(false)
                                    setColor(initialColorValues)
                                  } else {
                                    setMenu(true)
                                    setEdit(true)
                                    setColor(one)
                                  }
                                }}
                              >
                                {edit ? (
                                  <i className="fas fa-close"></i>
                                ) : (
                                  <i className="fas fa-edit"></i>
                                )}
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm ms-2"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setError("")
                                  setAddEditErrorMsg("")
                                  handleDelete(one._id)
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </Link>
                            </>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Tutorials.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Tutorials)
