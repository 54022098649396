/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Form,
    FormFeedback,
    Label,
    Input,
    FormGroup,
    Spinner,
} from "reactstrap"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// Redux
import { connect, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

import avatar from "../../assets/images/users/user-4.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import axios from "axios/axios"

const UserProfile = (props) => {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    // document.title = "Profile"
    const user = sessionStorage.getItem("admin")
        ? JSON.parse(sessionStorage.getItem("admin"))
        : JSON.parse(sessionStorage.getItem("callCenter"))
    const [error, setError] = useState()
    const [oldPassword, setOldPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h5 className="page-title">Profile</h5>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="mx-3 ">
                                            <div
                                                className="avatar-md rounded-circle img-thumbnail"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {sessionStorage.getItem(
                                                    "admin"
                                                ) ? (
                                                    <i
                                                        className="fas fa-user-cog"
                                                        style={{
                                                            fontSize: "1.5vw",
                                                        }}
                                                    ></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-headset"
                                                        style={{
                                                            fontSize: "1.5vw",
                                                        }}
                                                    ></i>
                                                )}
                                            </div>
                                        </div>
                                        <div className="align-self-center flex-1">
                                            <div className="text-muted">
                                                <h5>{user.name}</h5>
                                                <p className="mb-1">
                                                    {user.email}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className="card-title mb-4">Change Password</h4>

                    <Card>
                        <CardBody>
                            {error && <Alert color="danger">{error}</Alert>}
                            <Form
                                className="row g-3 needs-validation mt-2"
                                style={{ width: "100%" }}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    if (newPassword != confirmPassword) {
                                        setError(
                                            "New password and Confirm password do not match."
                                        )
                                        return
                                    }
                                    setLoadingSubmit(true)
                                    setError(null)
                                    axios
                                        .post(
                                            sessionStorage.getItem("admin")
                                                ? "admin/changePassword"
                                                : sessionStorage.getItem(
                                                      "doctor"
                                                  )
                                                ? "doctorAuth/changePassword"
                                                : "assistant/changePassword",
                                            {
                                                oldPassword,
                                                newPassword,
                                            },
                                            {
                                                headers: {
                                                    Authorization:
                                                        sessionStorage.getItem(
                                                            "token"
                                                        ),
                                                },
                                            }
                                        )
                                        .then(() => {
                                            setLoadingSubmit(false)
                                            sessionStorage.clear()
                                            window.location.replace("/")
                                        })
                                        .catch((e) => {
                                            console.log(e)
                                            setError("Wrong Password")
                                            setLoadingSubmit(false)
                                        })
                                }}
                            >
                                <Row>
                                    <Col md={4} xs={12}>
                                        <FormGroup className="mb-3">
                                            <Label
                                                htmlFor="example-text-input"
                                                style={{ color: "#5B626B" }}
                                            >
                                                Old Passsword
                                            </Label>
                                            <Input
                                                required
                                                id="oldPassword"
                                                className="form-control"
                                                type="password"
                                                placeholder="Old Passsword"
                                                value={oldPassword}
                                                invalid={
                                                    error &&
                                                    (error.includes("Old") ||
                                                        error.includes("all"))
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    setOldPassword(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <FormGroup className="mb-3">
                                            <Label
                                                htmlFor="example-text-input"
                                                style={{ color: "#5B626B" }}
                                            >
                                                Confirm Passsword
                                            </Label>
                                            <Input
                                                required
                                                id="confirmPassword"
                                                className="form-control"
                                                type="password"
                                                placeholder="Confirm Passsword"
                                                value={confirmPassword}
                                                invalid={
                                                    error &&
                                                    (error.includes(
                                                        "Confirm"
                                                    ) ||
                                                        error.includes("all"))
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    setConfirmPassword(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <FormGroup className="mb-3">
                                            <Label
                                                htmlFor="example-text-input"
                                                style={{ color: "#5B626B" }}
                                            >
                                                New Passsword
                                            </Label>
                                            <Input
                                                required
                                                id="newPassword"
                                                className="form-control"
                                                type="password"
                                                placeholder="New Passsword"
                                                value={newPassword}
                                                invalid={
                                                    error &&
                                                    (error.includes("New") ||
                                                        error.includes("all"))
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) => {
                                                    setNewPassword(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="col-12">
                                    <button
                                        className="btn btn-primary"
                                        type={loadingSubmit ? null : "submit"}
                                    >
                                        {loadingSubmit ? (
                                            <Spinner
                                                size={"sm"}
                                                color="secondary"
                                            />
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

UserProfile.propTypes = {
    editProfile: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
}

const mapStatetoProps = (state) => {
    const { error, success } = state.Profile
    return { error, success }
}

export default withRouter(
    connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
)
