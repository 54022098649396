/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs*/
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  CardHeader,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"
import AddEditProduct from "./AddEditProduct.js"

//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"

const Products = () => {
  const [activeTab, toggleTab] = useState("1")

  const columns = sessionStorage.getItem("admin")
    ? [
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
        },
        {
          label: "Stock",
          field: "stock",
          sort: "asc",
        },
        {
          label: "Sub Category",
          field: "subCategory",
          sort: "asc",
        },
        {
          label: "",
          field: "actions",
        },
      ]
    : [
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },

        {
          label: "Price",
          field: "price",
          sort: "asc",
        },
        {
          label: "Stock",
          field: "stock",
          sort: "asc",
        },
        {
          label: "Sub Category",
          field: "subCategory",
          sort: "asc",
        },
      ]
  const productInitial = {
    name: "",
    nameAr: "",
    nameFr: "",
    nameIt: "",
    description: "",
    images: [],
    price: 0,
    stock: 0,
    aroma: "",
    balance: "",
    body: "",
    intensity: "",
    sweetness: "",
    iconName: "",
    type: "",
    packageType: "",
    weight: "",
    pieces: "",
    color: "",
  }
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [product, setProduct] = useState(productInitial)
  const [selectedProduct, setSelectedProduct] = useState()
  // document.title = "Products"

  useEffect(() => {
    axios
      .get("products/all", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        setProducts(res.data.products.flatMap((one) => one.product))
      })
      .catch((e) => {
        console.log(e)
      })
  }, [reload])
  useEffect(() => {
    axios
      .get("products/categories", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        setCategories(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
    axios
      .get("products/subCategories", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res)
        setSubCategories(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Products</h5>
              </Col>
            </Row>
          </div>
          {sessionStorage.getItem("admin") && (
            <Row className="mb-4">
              <Col>
                <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    style={{ backgroundColor: "#E9ECEF" }}
                    onClick={() => {
                      setMenu(!menu)
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h6
                      style={{
                        fontWeight: "bold",
                        color: "#5B626B",
                      }}
                    >
                      {!selectedProduct ? "Add" : "Edit"} Product
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddEditProduct
                      categories={categories}
                      subCategories={subCategories}
                      product={product}
                      selectedProduct={selectedProduct}
                      setProduct={setProduct}
                      setSelectedProduct={setSelectedProduct}
                      setReload={setReload}
                    />
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Products List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: products.map((one) => {
                        return {
                          ...one,
                          subCategory: one.subCategory && one.subCategory.name,
                          actions: sessionStorage.getItem("admin") ? (
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm"
                              onClick={(e) => {
                                e.preventDefault()
                                if (
                                  selectedProduct &&
                                  selectedProduct._id === one._id
                                ) {
                                  setProduct(productInitial)
                                  setSelectedProduct(null)
                                  setMenu(false)
                                } else {
                                  setProduct(one)
                                  setSelectedProduct(one)
                                  setMenu(true)
                                }
                              }}
                            >
                              {selectedProduct &&
                              selectedProduct._id === one._id ? (
                                <i
                                  className="ion ion-md-close"
                                  style={{
                                    color: "white",
                                  }}
                                ></i>
                              ) : (
                                <i className="fas fa-edit"></i>
                              )}
                            </Link>
                          ) : (
                            <></>
                          ),
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Products.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Products)
