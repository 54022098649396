/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from "axios/axios"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Dropzone from "react-dropzone"
import OutlinedInput from "@mui/material/OutlinedInput"
// import TextareaAutosize from "@mui/material/TextareaAutosize"
// import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import {
  // Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import Select from "react-select"

const AddEditProduct = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [editing, setEditing] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [category, setCategory] = useState()
  const [subCategory, setSubCategory] = useState()
  const [Error, setError] = useState("")
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function handleAcceptedFiles(files) {
    setFiles(files)
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(files)
  }
  const handleSubmit = () => {
    if (props.selectedProduct) {
      handleEdit()
    } else {
      handleAdd()
    }
  }

  const handleAdd = () => {
    setLoadingSubmit(true)
    var fdata = new FormData()
    for (let one of files) {
      fdata.append("files", one)
    }
    fdata.append("name", props.product.name)
    fdata.append("nameAr", props.product.nameAr)
    fdata.append("nameFr", props.product.nameFr)
    // fdata.append("nameIt", props.product.nameIt)
    if (subCategory) fdata.append("subCategory", subCategory.value)
    fdata.append("description", props.product.description)
    fdata.append("descriptionAr", props.product.descriptionAr)
    fdata.append("packageType", props.product.packageType)
    fdata.append("price", props.product.price)
    fdata.append("stock", props.product.stock)
    fdata.append("aroma", props.product.aroma)
    fdata.append("roundness", props.product.balance)
    fdata.append("body", props.product.body)
    fdata.append("intensity", props.product.intensity)
    fdata.append("sweetness", props.product.sweetness)
    fdata.append("iconName", props.product.iconName)
    fdata.append("type", props.product.type)
    if (props.product.packageType == "weighed") {
      fdata.append("weight", props.product.weight)
    } else if (props.product.packageType == "pieces") {
      fdata.append("pieces", props.product.pieces)
    } else if (props.product.packageType == "colored") {
      fdata.append("color", props.product.color)
    }
    axios
      .post("products/addProduct", fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then(() => {
        setLoadingSubmit(false)
        setSelectedFiles([])
        setFiles([])
        props.setReload((prev) => !prev)
        props.setProduct({
          name: "",
          description: "",
          descriptionAr: "",
          images: [],
          price: 0,
          stock: 0,
          aroma: 0,
          balance: 0,
          body: 0,
          intensity: 0,
          sweetness: 0,
          packageType: "",
          type: "",
          weight: "",
          pieces: "",
          color: "",
        })
      })
      .catch((e) => {
        console.log(e)
        setError(e.response.data)
        setLoadingSubmit(false)
      })
  }

  const handleEdit = () => {
    setLoadingSubmit(true)
    let edit = false
    var fdata = new FormData()
    if (files.length > 0) {
      for (let one of files) {
        fdata.append("files", one)
      }
      edit = true
    }
    if (props.product.name != props.selectedProduct.name) {
      fdata.append("name", props.product.name)
      edit = true
    }
    if (props.product.nameAr != props.selectedProduct.nameAr) {
      fdata.append("nameAr", props.product.nameAr)
      edit = true
    }
    if (props.product.nameFr != props.selectedProduct.nameFr) {
      fdata.append("nameFr", props.product.nameFr)
      edit = true
    }
    // if (props.product.nameIt != props.selectedProduct.nameIt) {
    //   fdata.append("nameIt", props.product.nameIt)
    //   edit = true
    // }
    if (props.product.description != props.selectedProduct.description) {
      fdata.append("description", props.product.description)
      edit = true
    }
    if (props.product.descriptionAr != props.selectedProduct.descriptionAr) {
      fdata.append("descriptionAr", props.product.descriptionAr)
      edit = true
    }
    if (props.product.packageType != props.selectedProduct.packageType) {
      fdata.append("packageType", props.product.packageType)
      edit = true
    }
    if (props.product.price != props.selectedProduct.price) {
      fdata.append("price", props.product.price)
      edit = true
    }
    if (props.product.stock != props.selectedProduct.stock) {
      fdata.append("stock", props.product.stock)
      edit = true
    }
    if (props.product.aroma != props.selectedProduct.aroma) {
      fdata.append("aroma", props.product.aroma)
      edit = true
    }
    if (props.product.balance != props.selectedProduct.balance) {
      fdata.append("roundness", props.product.balance)
      edit = true
    }
    if (props.product.body != props.selectedProduct.body) {
      fdata.append("body", props.product.body)
      edit = true
    }
    if (props.product.intensity != props.selectedProduct.intensity) {
      fdata.append("intensity", props.product.intensity)
      edit = true
    }
    if (props.product.type != props.selectedProduct.type) {
      fdata.append("type", props.product.type)
      edit = true
    }
    if (props.product.sweetness != props.selectedProduct.sweetness) {
      fdata.append("sweetness", props.product.sweetness)
      edit = true
    }
    if (props.product.iconName != props.selectedProduct.iconName) {
      fdata.append("iconName", props.product.iconName)
      edit = true
    }
    if (props.product.packageType == "weighed") {
      if (props.product.weight != props.selectedProduct.weight) {
        fdata.append("weight", props.product.weight)
        edit = true
      }
    } else if (props.product.packageType == "pieces") {
      if (props.product.pieces != props.selectedProduct.pieces) {
        fdata.append("pieces", props.product.pieces)
        edit = true
      }
    } else if (props.product.packageType == "colored") {
      if (props.product.color != props.selectedProduct.color) {
        fdata.append("color", props.product.color)
        edit = true
      }
    }
    if (
      subCategory &&
      subCategory.value != props.selectedProduct.subCategory._id
    ) {
      fdata.append("subCategory", subCategory.value)
      edit = true
    }
    console.log(edit)
    if (edit) {
      axios
        .put(`products/updateProduct/${props.selectedProduct._id}`, fdata, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then(() => {
          setEditing(false)
          props.setSelectedProduct(null)
          setSelectedFiles([])
          setFiles([])
          setLoadingSubmit(false)
          props.setReload((prev) => !prev)
          props.setProduct({
            name: "",
            nameAr: "",
            nameFr: "",
            // nameIt: "",
            description: "",
            descriptionAr: "",
            images: [],
            price: 0,
            stock: 0,
            aroma: 0,
            balance: 0,
            body: 0,
            intensity: 0,
            type: "",
            sweetness: 0,
            packageType: "",
            weight: "",
            pieces: "",
            color: "",
          })
        })
        .catch((e) => {
          console.log(e)
          setLoadingSubmit(false)
        })
    }
    setLoadingSubmit(false)
  }
  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this product, do you wish to continue?"
    )
    if (confirm) {
      axios
        .delete(`products/deleteProduct/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log(res)
          props.setProduct({
            titleEnglish: "",
            descriptionEnglish: "",
            bodyEnglish: "",
            titleArabic: "",
            descriptionArabic: "",
            bodyArabic: "",
            img: null,
          })
          props.setSelectedProduct(null)
          props.setReload((prev) => !prev)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
  const [remainingCharacters, setRemainingCharacters] = useState(75)
  const [remainingCharacters1, setRemainingCharacters1] = useState(75)
  const [remainingCharacters2, setRemainingCharacters2] = useState(500)
  const [remainingCharacters3, setRemainingCharacters3] = useState(75)
  const [remainingCharacters4, setRemainingCharacters4] = useState(500)
  return (
    <>
      <Form
        className="row g-3"
        style={{ width: "100%", marginTop: "1vh" }}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
          // validation.handleSubmit()
        }}
      >
        {Error && <Alert color="danger">{Error}</Alert>}

        <Row style={{ width: "100%" }}>
          <FormGroup className="mt-3">
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              Type
            </Label>
            {editing || !props.selectedProduct ? (
              <Input
                id="type"
                className="form-control"
                type="select"
                rows="2"
                placeholder="Package Type"
                value={props.product.type}
                onChange={(e) => {
                  props.setProduct({
                    ...props.product,
                    type: e.target.value,
                    packageType: "",
                  })
                }}
              >
                <option value="" label="Type" disabled />
                <option value="coffee" label="Coffee" />
                <option value="beans" label="Beans" />
                <option value="general" label="General" />
              </Input>
            ) : (
              <p style={{ color: "#5B626B" }}>
                {props.selectedProduct.type ? props.selectedProduct.type : ""}
              </p>
            )}
          </FormGroup>
        </Row>
        {props.product.type != "" && (
          <>
            <Row style={{ width: "100%" }}>
              <Col md={6} xs={12}>
                <Row>
                  <FormGroup className="mt-3 mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Name
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <>
                        <OutlinedInput
                          maxLength={75}
                          style={{ height: "4vh" }}
                          id="name"
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          value={props.product.name}
                          onChange={(e) => {
                            const newValue = e.target.value
                            setRemainingCharacters(75 - newValue.length) // Update remaining characters count
                            console.log(75 - props.product.name.length)
                            console.log(newValue.length)
                            if (newValue.length < 75) {
                              props.setProduct({
                                ...props.product,
                                name: e.target.value,
                              })
                            }
                          }}
                          endAdornment={
                            <InputAdornment position="end" color="primary">
                              {" "}
                              {remainingCharacters}
                            </InputAdornment>
                          }
                        />
                        {/* <div className="character-count">
                          {remainingCharacters}
                        </div> */}
                      </>
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.name
                          ? props.selectedProduct.name
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      الاسم
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <OutlinedInput
                        maxLength={75}
                        style={{ height: "4vh" }}
                        id="nameAr"
                        className="form-control"
                        type="text"
                        placeholder="الاسم"
                        value={props.product.nameAr}
                        onChange={(e) => {
                          const newValue = e.target.value
                          setRemainingCharacters1(75 - newValue.length) // Update remaining characters count
                          console.log(75 - props.product.nameAr.length)
                          console.log(newValue.length)
                          if (newValue.length < 75) {
                            props.setProduct({
                              ...props.product,
                              nameAr: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end" color="primary">
                            {" "}
                            {remainingCharacters1}
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.nameAr
                          ? props.selectedProduct.nameAr
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                {/* <Row>
                  <FormGroup className="mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Name (it)
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <OutlinedInput
                        maxLength={75}
                        style={{ height: "4vh" }}
                        id="nameIt"
                        className="form-control"
                        type="text"
                        placeholder="Name (it)"
                        value={props.product.nameIt}
                        onChange={(e) => {
                          const newValue = e.target.value
                          setRemainingCharacters2(75 - newValue.length) // Update remaining characters count
                          console.log(75 - props.product.nameIt.length)
                          console.log(newValue.length)
                          if (newValue.length < 75) {
                            props.setProduct({
                              ...props.product,
                              nameIt: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end" color="primary">
                            {" "}
                            {remainingCharacters2}
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.nameIt
                          ? props.selectedProduct.nameIt
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row> */}
                <Row>
                  <FormGroup className="mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Name (Ar)
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <OutlinedInput
                        maxLength={75}
                        style={{ height: "4vh" }}
                        id="nameFr"
                        className="form-control"
                        type="text"
                        placeholder="Name (Ar)"
                        value={props.product.nameFr}
                        onChange={(e) => {
                          const newValue = e.target.value
                          setRemainingCharacters3(75 - newValue.length) // Update remaining characters count
                          console.log(75 - props.product.nameFr.length)
                          console.log(newValue.length)
                          if (newValue.length < 75) {
                            props.setProduct({
                              ...props.product,
                              nameFr: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end" color="primary">
                            {" "}
                            {remainingCharacters3}
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.nameFr
                          ? props.selectedProduct.nameFr
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className=" mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Description
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <OutlinedInput
                        multiline
                        maxLength={500}
                        style={{ resize: "auto" }}
                        // style={{ height: "20vh" }}
                        id="description"
                        className="form-control"
                        type="textarea"
                        // rows="5"
                        placeholder="Description"
                        value={props.product.description}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 500)
                          setRemainingCharacters4(500 - newValue.length) // Update remaining characters count
                          if (newValue.length < 500) {
                            props.setProduct({
                              ...props.product,
                              description: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end" color="primary">
                            {" "}
                            {remainingCharacters4}
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.description
                          ? props.selectedProduct.description
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className=" mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Description (Ar)
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <OutlinedInput
                        multiline
                        maxLength={500}
                        style={{ resize: "auto" }}
                        // style={{ height: "20vh" }}
                        id="description"
                        className="form-control"
                        type="textarea"
                        // rows="5"
                        placeholder="Description"
                        value={props.product.descriptionAr}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 500)
                          setRemainingCharacters2(500 - newValue.length) // Update remaining characters count
                          if (newValue.length < 500) {
                            props.setProduct({
                              ...props.product,
                              descriptionAr: e.target.value,
                            })
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end" color="primary">
                            {" "}
                            {remainingCharacters2}
                          </InputAdornment>
                        }
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.descriptionAr
                          ? props.selectedProduct.descriptionAr
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup className="mb-3">
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Package Type
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <Input
                        id="packageType"
                        className="form-control"
                        type="select"
                        rows="2"
                        placeholder="Package Type"
                        value={props.product.packageType}
                        onChange={(e) => {
                          props.setProduct({
                            ...props.product,
                            packageType: e.target.value,
                          })
                        }}
                      >
                        <option value="" label="Package Type" disabled />
                        <option value="weighed" label="Weighed" />
                        {props.product.type != "beans" && (
                          <option value="pieces" label="Pieces" />
                        )}
                        {props.product.type == "general" && (
                          <option value="colored" label="Colored" />
                        )}
                      </Input>
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.packageType
                          ? props.selectedProduct.packageType
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
              </Col>
              <Col md={6} xs={12}>
                {(editing || !props.selectedProduct) && (
                  <Row>
                    <FormGroup className="mt-3 mb-3">
                      <Label
                        htmlFor="example-text-input"
                        style={{ color: "#5B626B" }}
                      >
                        Category
                      </Label>

                      <Select
                        id="category"
                        // value={category}
                        onChange={(value) => {
                          setCategory(value)
                          setSubCategory(null)
                        }}
                        options={props.categories.map((one) => {
                          return {
                            label: one.name,
                            value: one._id,
                          }
                        })}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Row>
                )}
                <Row>
                  <FormGroup
                    className={`${
                      editing || !props.selectedProduct ? "" : "mt-3"
                    } mb-3`}
                  >
                    <Label
                      htmlFor="example-text-input"
                      style={{ color: "#5B626B" }}
                    >
                      Sub Category
                    </Label>
                    {editing || !props.selectedProduct ? (
                      <Select
                        id="subcategory"
                        // value={category}
                        onChange={(value) => {
                          setSubCategory(value)
                        }}
                        options={props.subCategories
                          .filter(
                            (one) => category && one.category == category.value
                          )
                          .map((one) => {
                            return {
                              label: one.name,
                              value: one._id,
                            }
                          })}
                        classNamePrefix="select2-selection"
                      />
                    ) : (
                      <p style={{ color: "#5B626B" }}>
                        {props.selectedProduct.subCategory.name
                          ? props.selectedProduct.subCategory.name
                          : ""}
                      </p>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <Col md={4} xs={12}>
                    <FormGroup className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        style={{ color: "#5B626B" }}
                      >
                        Price
                      </Label>
                      {editing || !props.selectedProduct ? (
                        <Input
                          id="price"
                          className="form-control"
                          type="text"
                          placeholder="Price"
                          value={props.product.price}
                          onChange={(e) => {
                            props.setProduct({
                              ...props.product,
                              price: e.target.value,
                            })
                          }}
                        />
                      ) : (
                        <p style={{ color: "#5B626B" }}>
                          {props.selectedProduct.price
                            ? props.selectedProduct.price.toFixed(2) + " EGP"
                            : ""}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12}>
                    <FormGroup className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        style={{ color: "#5B626B" }}
                      >
                        Stock
                      </Label>
                      {editing || !props.selectedProduct ? (
                        <Input
                          id="stock"
                          className="form-control"
                          type="text"
                          placeholder="Stock"
                          value={props.product.stock}
                          onChange={(e) => {
                            props.setProduct({
                              ...props.product,
                              stock: e.target.value,
                            })
                          }}
                        />
                      ) : (
                        <p style={{ color: "#5B626B" }}>
                          {props.selectedProduct.stock
                            ? props.selectedProduct.stock
                            : ""}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4} xs={12}>
                    {props.product.packageType == "weighed" ? (
                      <FormGroup className="mb-3">
                        <Label
                          htmlFor="example-text-input"
                          style={{ color: "#5B626B" }}
                        >
                          Weight (gm)
                        </Label>
                        {editing || !props.selectedProduct ? (
                          <Input
                            id="weight"
                            className="form-control"
                            type="select"
                            placeholder="Weight"
                            value={props.product.weight}
                            onChange={(e) => {
                              props.setProduct({
                                ...props.product,
                                weight: e.target.value,
                              })
                            }}
                          >
                            <option value="" label="Weight" disabled />
                            <option value={250} label="250" />
                            <option value={500} label="500" />
                            <option value={1000} label="1000" />
                            <option value={1500} label="1500" />
                            <option value={2500} label="2500" />
                          </Input>
                        ) : (
                          <p
                            style={{
                              color: "#5B626B",
                            }}
                          >
                            {props.selectedProduct.weight
                              ? props.selectedProduct.weight
                              : ""}
                          </p>
                        )}
                      </FormGroup>
                    ) : props.product.packageType == "pieces" ? (
                      <FormGroup className="mb-3">
                        <Label
                          htmlFor="example-text-input"
                          style={{ color: "#5B626B" }}
                        >
                          Pieces
                        </Label>
                        {editing || !props.selectedProduct ? (
                          <Input
                            id="pieces"
                            className="form-control"
                            type="text"
                            placeholder="Pieces"
                            value={props.product.pieces}
                            onChange={(e) => {
                              props.setProduct({
                                ...props.product,
                                pieces: e.target.value,
                              })
                            }}
                          />
                        ) : (
                          <p
                            style={{
                              color: "#5B626B",
                            }}
                          >
                            {props.selectedProduct.pieces
                              ? props.selectedProduct.pieces
                              : ""}
                          </p>
                        )}
                      </FormGroup>
                    ) : (
                      props.product.packageType == "colored" && (
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{
                              color: "#5B626B",
                            }}
                          >
                            Color
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="color"
                              className="form-control"
                              type="select"
                              placeholder="Color"
                              value={props.product.color}
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  color: e.target.value,
                                })
                              }}
                            >
                              <option value="" label="Color" disabled />
                              {[
                                "Red",
                                "Blue",
                                "Green",
                                "Yellow",
                                "Black",
                                "White",
                                "Silver",
                                "Gray",
                                "Orange",
                                "Purple",
                                "Brown",
                                "Gold",
                                "Copper",
                                "Bronze",
                                "Beige",
                              ].map((one) => (
                                <option key={one} value={one} label={one} />
                              ))}
                            </Input>
                          ) : (
                            <p
                              style={{
                                color: "#5B626B",
                              }}
                            >
                              {props.selectedProduct.color
                                ? props.selectedProduct.color
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      )
                    )}
                  </Col>
                </Row>
                {props.product.type !== "general" && (
                  <>
                    <Row>
                      <Col md={4} xs={6}>
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{ color: "#5B626B" }}
                          >
                            Aroma
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="aroma"
                              className="form-control"
                              type="text"
                              placeholder="Aroma"
                              value={
                                props.product.aroma ? props.product.aroma : ""
                              }
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  aroma: e.target.value,
                                })
                              }}
                            />
                          ) : (
                            <p style={{ color: "#5B626B" }}>
                              {props.selectedProduct.aroma
                                ? props.selectedProduct.aroma
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={6}>
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{ color: "#5B626B" }}
                          >
                            Roundness
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="balance"
                              className="form-control"
                              type="text"
                              placeholder="Balance"
                              value={
                                props.product.balance
                                  ? props.product.balance
                                  : ""
                              }
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  balance: e.target.value,
                                })
                              }}
                            />
                          ) : (
                            <p style={{ color: "#5B626B" }}>
                              {props.selectedProduct.balance
                                ? props.selectedProduct.balance
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={6}>
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{ color: "#5B626B" }}
                          >
                            Body
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="body"
                              className="form-control"
                              type="text"
                              placeholder="Body"
                              value={
                                props.product.body ? props.product.body : ""
                              }
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  body: e.target.value,
                                })
                              }}
                            />
                          ) : (
                            <p style={{ color: "#5B626B" }}>
                              {props.selectedProduct.body
                                ? props.selectedProduct.body
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} xs={6}>
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{ color: "#5B626B" }}
                          >
                            Intensity
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="intensity"
                              className="form-control"
                              type="text"
                              placeholder="Intensity"
                              value={
                                props.product.intensity
                                  ? props.product.intensity
                                  : ""
                              }
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  intensity: e.target.value,
                                })
                              }}
                            />
                          ) : (
                            <p style={{ color: "#5B626B" }}>
                              {props.selectedProduct.intensity
                                ? props.selectedProduct.intensity
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={4} xs={6}>
                        <FormGroup className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            style={{ color: "#5B626B" }}
                          >
                            Sweetness
                          </Label>
                          {editing || !props.selectedProduct ? (
                            <Input
                              id="sweetness"
                              className="form-control"
                              type="text"
                              placeholder="Sweetness"
                              value={
                                props.product.sweetness
                                  ? props.product.sweetness
                                  : ""
                              }
                              onChange={(e) => {
                                props.setProduct({
                                  ...props.product,
                                  sweetness: e.target.value,
                                })
                              }}
                            />
                          ) : (
                            <p style={{ color: "#5B626B" }}>
                              {props.selectedProduct.sweetness
                                ? props.selectedProduct.sweetness
                                : ""}
                            </p>
                          )}
                        </FormGroup>
                      </Col>

                      {props.product.type === "coffee" && (
                        <>
                          <Col md={4} xs={6}>
                            <FormGroup className="mb-3">
                              <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                              >
                                Icon Name
                              </Label>
                              {editing || !props.selectedProduct ? (
                                <Input
                                  required
                                  id="iconName"
                                  className="form-control"
                                  type="text"
                                  placeholder="Icon Name"
                                  value={props.product.iconName}
                                  onChange={(e) => {
                                    props.setProduct({
                                      ...props.product,
                                      iconName: e.target.value,
                                    })
                                  }}
                                />
                              ) : (
                                <p style={{ color: "#5B626B" }}>
                                  {props.selectedProduct.iconName
                                    ? props.selectedProduct.iconName
                                    : ""}
                                </p>
                              )}
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col>
                <FormGroup className="mb-3">
                  <Label
                    htmlFor="example-text-input"
                    style={{ color: "#5B626B" }}
                  >
                    Images
                  </Label>
                  {editing || !props.selectedProduct ? (
                    <>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <Row>
                        {selectedFiles.map((f, i) => {
                          return (
                            <Col md={2} key={i + "-file"}>
                              <div className="p-2">
                                <Row
                                  className="align-items-center"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <Col className="col-auto">
                                    <i
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let newSelectedFiles = [
                                          ...selectedFiles,
                                        ]
                                        newSelectedFiles.splice(i, 1)
                                        setSelectedFiles(newSelectedFiles)
                                        let newFiles = [...files]
                                        newFiles.splice(i, 1)
                                        setFiles(newFiles)
                                      }}
                                      className="ion ion-md-close"
                                    ></i>
                                  </Col>
                                </Row>
                                <Row
                                  className="align-items-center"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Col className="col-auto">
                                    {f.type.includes("image") ? (
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    ) : (
                                      // <Card
                                      // 	style={{
                                      // 		height: "80",
                                      // 		// display: "flex",
                                      // 		// alignItems: "center",
                                      // 	}}
                                      // >
                                      f.name
                                      // </Card>
                                    )}
                                  </Col>
                                </Row>

                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Row>
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : (
                    <div>
                      <Row>
                        {props.selectedProduct.images.map((one, index) => (
                          <Col md={3} xs={6} key={`img${index}`}>
                            <img
                              src={one}
                              alt=""
                              style={{
                                maxWidth: "15vw",
                                maxHeight: "15vw",
                              }}
                            ></img>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {editing || !props.selectedProduct ? (
              <div className="col-12">
                {editing && (
                  <button
                    className="btn btn-secondary"
                    style={{ marginRight: "1vw" }}
                    onClick={(e) => {
                      e.preventDefault()
                      setEditing(false)
                    }}
                  >
                    <i className="ion ion-md-close"></i>
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  type={loadingSubmit ? null : "submit"}
                >
                  {loadingSubmit ? (
                    <Spinner size={"sm"} color="secondary" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="col-12">
                <button
                  className="btn btn-primary"
                  style={{ marginRight: "1vw" }}
                  onClick={(e) => {
                    e.preventDefault()
                    setEditing(true)
                  }}
                >
                  <i className="fas fa-edit"></i>
                </button>

                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault()
                    handleDelete(props.selectedProduct._id)
                  }}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            )}
          </>
        )}
      </Form>
    </>
  )
}
AddEditProduct.propTypes = {
  categories: PropTypes.any,
  subCategories: PropTypes.any,
  product: PropTypes.any,
  selectedProduct: PropTypes.any,
  setProduct: PropTypes.any,
  setSelectedProduct: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditProduct
