/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Col,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"
import OrderDetails from "./OrderDetails.js"

//i18n
import { withTranslation } from "react-i18next"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import { format } from "date-fns"
import classnames from "classnames"

const Orders = () => {
  const [activeTab, toggleTab] = useState("1")

  const columns = [
    {
      label: "User",
      field: "userName",
      sort: "asc",
    },
    {
      label: "Address",
      field: "address",
      sort: "asc",
    },
    {
      label: "Phone",
      field: "phone",
      sort: "asc",
    },
    { label: "paymentMethod", field: "paymentMethod", sort: "asc" },

    {
      label: "Products",
      field: "productsNum",
      sort: "asc",
    },
    {
      label: "Total",
      field: "total",
      sort: "asc",
    },

    {
      label: "Status",
      field: "status",
      sort: "asc",
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
    },
  ]
  const orderInitial = {
    titleEnglish: "",
    descriptionEnglish: "",
    bodyEnglish: "",
    titleArabic: "",
    descriptionArabic: "",
    bodyArabic: "",
    img: null,
  }
  const [menu, setMenu] = useState(false)
  const [reload, setReload] = useState(false)
  const [orders, setOrders] = useState([])
  const [allOrders, setAllOrders] = useState([])
  const [order, setOrder] = useState(orderInitial)
  const [selectedOrder, setSelectedOrder] = useState()
  const [pendindOrders, setPendingOrders] = useState([])
  const [deliveredOrders, setDeliveredOrders] = useState([])
  const [processingOrders, setProcessingOrders] = useState([])
  const [cancelledOrders, setCancelledOrders] = useState([])
  const [shippedOrders, setShippedOrders] = useState([])
  // document.title = "Orders"

  useEffect(() => {
    axios
      .get("orders/all", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res.data.orders)
        setOrders(res.data.orders)
        setAllOrders(res.data.orders)
        res.data.orders.map((order) => {
          if (order.status === "pending") {
            setPendingOrders((prev) => [...prev, order])
          }
          if (order.status === "delivered") {
            setDeliveredOrders((prev) => [...prev, order])
          }
          if (order.status === "processing") {
            setProcessingOrders((prev) => [...prev, order])
          }
          if (order.status === "shipped") {
            setShippedOrders((prev) => [...prev, order])
          }
          if (order.status === "cancelled") {
            setCancelledOrders((prev) => [...prev, order])
          }
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [reload])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Orders</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    if (selectedOrder) setMenu(!menu)
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    Order Details
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedOrder && (
                    <OrderDetails
                      selectedOrder={selectedOrder}
                      setSelectedOrder={setSelectedOrder}
                      setReload={setReload}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Orders List</h6>
                </CardHeader>
                <CardBody>
                  <Card>
                    <ul
                      className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1")
                            setOrders([...allOrders])
                          }}
                        >
                          All
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2")
                            setOrders([...pendindOrders])
                          }}
                        >
                          Pending
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3")
                            setOrders([...deliveredOrders])
                          }}
                        >
                          Delivered
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            toggleTab("4")
                            setOrders([...processingOrders])
                          }}
                        >
                          Processing
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "5",
                          })}
                          onClick={() => {
                            toggleTab("5")
                            setOrders([...shippedOrders])
                          }}
                        >
                          Shipped
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to="#"
                          className={classnames({
                            active: activeTab === "6",
                          })}
                          onClick={() => {
                            toggleTab("6")
                            setOrders([...cancelledOrders])
                          }}
                        >
                          Cancelled
                        </NavLink>
                      </NavItem>
                    </ul>
                  </Card>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: orders.map((one) => {
                        return {
                          ...one,
                          paymentMethod:
                            one.paymentMethod === "cod"
                              ? "Cash on Delivery"
                              : one.paymentMethod,
                          address: one.address
                            ? one.address.apartment +
                              ", " +
                              one.address.street +
                              ", " +
                              one.address.city +
                              ", " +
                              one.address.country +
                              " | " +
                              one.address.zipCode
                            : "",
                          userName: one.user ? one.user.name : "",
                          productsNum: one.products ? one.products.length : 0,
                          total: one.total ? one.total.toFixed(2) + " EGP" : "",
                          date: one.createdAt
                            ? format(new Date(one.createdAt), "yyyy-MM-dd")
                            : "",
                          clickEvent: () => {
                            if (
                              selectedOrder &&
                              selectedOrder._id === one._id
                            ) {
                              setSelectedOrder(null)
                              setMenu(false)
                            } else {
                              setSelectedOrder(one)
                              setMenu(true)
                            }
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            })
                          },
                        }
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Orders.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Orders)
