/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from "axios/axios"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap"

const AddEditBlog = (props) => {
	const [loadingSubmit, setLoadingSubmit] = useState(false)
	const [editing, setEditing] = useState(false)

	const handleSubmit = () => {
		if (props.selectedBlog) {
			handleEdit()
		} else {
			handleAdd()
		}
	}

	const handleAdd = () => {
		setLoadingSubmit(true)
		if (props.blog.img) {
			var fdata = new FormData()
			fdata.append("file", props.blog.img)
			axios
				.post(`data/upload`, fdata, {
					headers: {
						Authorization: sessionStorage.getItem("token"),
					},
				})
				.then((response) => {
					axios
						.post(
							"blog/add",
							{
								...(props.blog.titleEnglish != "" && {
									titleEnglish: props.blog.titleEnglish,
								}),
								...(props.blog.titleArabic != "" && {
									titleArabic: props.blog.titleArabic,
								}),
								...(props.blog.descriptionEnglish != "" && {
									descriptionEnglish: props.blog.descriptionEnglish,
								}),
								...(props.blog.descriptionArabic != "" && {
									descriptionArabic: props.blog.descriptionArabic,
								}),
								...(props.blog.bodyEnglish != "" && {
									bodyEnglish: props.blog.bodyEnglish,
								}),
								...(props.blog.bodyArabic != "" && {
									bodyArabic: props.blog.bodyArabic,
								}),
								img: response.data.url,
							},
							{
								headers: {
									Authorization: sessionStorage.getItem("token"),
								},
							}
						)
						.then((res) => {
							console.log(res)
							setLoadingSubmit(false)
							props.setReload((prev) => !prev)
							props.setBlog({
								titleEnglish: "",
								descriptionEnglish: "",
								bodyEnglish: "",
								titleArabic: "",
								descriptionArabic: "",
								bodyArabic: "",
								img: null,
							})
						})
						.catch((e) => {
							console.log(e)
							// if (e.response) {
							// 	if (e.response.data && e.response.data.includes("E11000")) {
							// 		setError({ email: "Email already in use" })
							// 	}
							// }
							setLoadingSubmit(false)
						})
				})
				.catch((e) => {
					setLoadingSubmit(false)
					console.log(e)
				})
		} else {
			axios
				.post(
					"blog/add",
					{
						...(props.blog.titleEnglish != "" && {
							titleEnglish: props.blog.titleEnglish,
						}),
						...(props.blog.titleArabic != "" && {
							titleArabic: props.blog.titleArabic,
						}),
						...(props.blog.descriptionEnglish != "" && {
							descriptionEnglish: props.blog.descriptionEnglish,
						}),
						...(props.blog.descriptionArabic != "" && {
							descriptionArabic: props.blog.descriptionArabic,
						}),
						...(props.blog.bodyEnglish != "" && {
							bodyEnglish: props.blog.bodyEnglish,
						}),
						...(props.blog.bodyArabic != "" && {
							bodyArabic: props.blog.bodyArabic,
						}),
					},
					{
						headers: {
							Authorization: sessionStorage.getItem("token"),
						},
					}
				)
				.then((res) => {
					console.log(res)
					setLoadingSubmit(false)
					props.setReload((prev) => !prev)
					props.setBlog({
						titleEnglish: "",
						descriptionEnglish: "",
						bodyEnglish: "",
						titleArabic: "",
						descriptionArabic: "",
						bodyArabic: "",
						img: null,
					})
				})
				.catch((e) => {
					console.log(e)
					// if (e.response) {
					// 	if (e.response.data && e.response.data.includes("E11000")) {
					// 		setError({ email: "Email already in use" })
					// 	}
					// }
					setLoadingSubmit(false)
				})
		}
	}

	const handleEdit = () => {
		setLoadingSubmit(true)
		if (props.blog.img && typeof props.blog.img != "string") {
			var fdata = new FormData()
			fdata.append("file", props.blog.img)
			axios
				.post(`data/upload`, fdata, {
					headers: {
						Authorization: sessionStorage.getItem("token"),
					},
				})
				.then((response) => {
					axios
						.patch(
							`blog/edit/${props.selectedBlog._id}`,
							{
								...(props.blog.titleEnglish !=
									props.selectedBlog.titleEnglish && {
									titleEnglish: props.blog.titleEnglish,
								}),
								...(props.blog.titleArabic !=
									props.selectedBlog.titleArabic && {
									titleArabic: props.blog.titleArabic,
								}),
								...(props.blog.descriptionEnglish !=
									props.selectedBlog.descriptionEnglish && {
									descriptionEnglish: props.blog.descriptionEnglish,
								}),
								...(props.blog.descriptionArabic !=
									props.selectedBlog.descriptionArabic && {
									descriptionArabic: props.blog.descriptionArabic,
								}),
								...(props.blog.bodyEnglish !=
									props.selectedBlog.bodyEnglish && {
									bodyEnglish: props.blog.bodyEnglish,
								}),
								...(props.blog.bodyArabic != props.selectedBlog.bodyArabic && {
									bodyArabic: props.blog.bodyArabic,
								}),
								img: response.data.url,
							},
							{
								headers: {
									Authorization: sessionStorage.getItem("token"),
								},
							}
						)
						.then((res) => {
							console.log(res)
							setLoadingSubmit(false)
							props.setReload((prev) => !prev)
							props.setBlog({
								titleEnglish: "",
								descriptionEnglish: "",
								bodyEnglish: "",
								titleArabic: "",
								descriptionArabic: "",
								bodyArabic: "",
								img: null,
							})
							props.setSelectedBlog(null)
							setEditing(false)
						})
						.catch((e) => {
							console.log(e)
							// if (e.response) {
							// 	if (e.response.data && e.response.data.includes("E11000")) {
							// 		setError({ email: "Email already in use" })
							// 	}
							// }
							setLoadingSubmit(false)
						})
				})
				.catch((e) => {
					setLoadingSubmit(false)
					console.log(e)
				})
		} else {
			axios
				.patch(
					`blog/edit/${props.selectedBlog._id}`,
					{
						...(props.blog.titleEnglish != props.selectedBlog.titleEnglish && {
							titleEnglish: props.blog.titleEnglish,
						}),
						...(props.blog.titleArabic != props.selectedBlog.titleArabic && {
							titleArabic: props.blog.titleArabic,
						}),
						...(props.blog.descriptionEnglish !=
							props.selectedBlog.descriptionEnglish && {
							descriptionEnglish: props.blog.descriptionEnglish,
						}),
						...(props.blog.descriptionArabic !=
							props.selectedBlog.descriptionArabic && {
							descriptionArabic: props.blog.descriptionArabic,
						}),
						...(props.blog.bodyEnglish != props.selectedBlog.bodyEnglish && {
							bodyEnglish: props.blog.bodyEnglish,
						}),
						...(props.blog.bodyArabic != props.selectedBlog.bodyArabic && {
							bodyArabic: props.blog.bodyArabic,
						}),
					},
					{
						headers: {
							Authorization: sessionStorage.getItem("token"),
						},
					}
				)
				.then((res) => {
					console.log(res)
					setLoadingSubmit(false)
					props.setReload((prev) => !prev)
					props.setBlog({
						titleEnglish: "",
						descriptionEnglish: "",
						bodyEnglish: "",
						titleArabic: "",
						descriptionArabic: "",
						bodyArabic: "",
						img: null,
					})
					props.setSelectedBlog(null)
					setEditing(false)
				})
				.catch((e) => {
					console.log(e)
					// if (e.response) {
					// 	if (e.response.data && e.response.data.includes("E11000")) {
					// 		setError({ email: "Email already in use" })
					// 	}
					// }
					setLoadingSubmit(false)
				})
		}
	}
	const handleDelete = (id) => {
		let confirm = window.confirm(
			"You are about delete this blog, do you wish to continue?"
		)
		if (confirm) {
			axios
				.delete(`blog/delete/${id}`, {
					headers: {
						Authorization: sessionStorage.getItem("token"),
					},
				})
				.then((res) => {
					console.log(res)
					props.setBlog({
						titleEnglish: "",
						descriptionEnglish: "",
						bodyEnglish: "",
						titleArabic: "",
						descriptionArabic: "",
						bodyArabic: "",
						img: null,
					})
					props.setSelectedBlog(null)
					props.setReload((prev) => !prev)
				})
				.catch((e) => {
					console.log(e)
				})
		}
	}
	return (
		<>
			<Form
				className="row g-3"
				style={{ width: "100%", marginTop: "1vh" }}
				onSubmit={(e) => {
					e.preventDefault()
					handleSubmit()
					// validation.handleSubmit()
				}}
			>
				<Row style={{ width: "100%" }}>
					<Col md={6} xs={12}>
						<Row>
							<FormGroup className="mt-3 mb-3">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									Title
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="titleEnglish"
										className="form-control"
										type="text"
										placeholder="Title"
										value={props.blog.titleEnglish}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												titleEnglish: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.titleEnglish
											? props.selectedBlog.titleEnglish
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="mt-3 mb-3">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									Description
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="descriptionEnglish"
										className="form-control"
										type="textarea"
										rows="2"
										placeholder="Description"
										value={props.blog.descriptionEnglish}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												descriptionEnglish: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.descriptionEnglish
											? props.selectedBlog.descriptionEnglish
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="mt-3 mb-3">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									Content
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="bodyEnglish"
										className="form-control"
										type="textarea"
										rows="4"
										placeholder="Content"
										value={props.blog.bodyEnglish}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												bodyEnglish: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.bodyEnglish
											? props.selectedBlog.bodyEnglish
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
					</Col>
					<Col md={6} xs={12}>
						<Row>
							<FormGroup className="mt-3 mb-3" dir="rtl">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									العنوان
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="titleArabic"
										className="form-control"
										type="text"
										placeholder="العنوان"
										value={props.blog.titleArabic}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												titleArabic: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.titleArabic
											? props.selectedBlog.titleArabic
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="mt-3 mb-3" dir="rtl">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									الوصف
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="descriptionArabic"
										className="form-control"
										type="textarea"
										rows="2"
										placeholder="الوصف"
										value={props.blog.descriptionArabic}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												descriptionArabic: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.descriptionArabic
											? props.selectedBlog.descriptionArabic
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
						<Row>
							<FormGroup className="mt-3 mb-3" dir="rtl">
								<Label
									htmlFor="example-text-input"
									style={{ color: "#5B626B" }}
								>
									المحتوى
								</Label>
								{editing || !props.selectedBlog ? (
									<Input
										id="bodyArabic"
										className="form-control"
										type="textarea"
										rows="4"
										placeholder="المحتوى"
										value={props.blog.bodyArabic}
										onChange={(e) => {
											props.setBlog({
												...props.blog,
												bodyArabic: e.target.value,
											})
										}}
									/>
								) : (
									<p style={{ color: "#5B626B" }}>
										{props.selectedBlog.bodyArabic
											? props.selectedBlog.bodyArabic
											: ""}
									</p>
								)}
							</FormGroup>
						</Row>
					</Col>
				</Row>
				<Row style={{ width: "100%" }}>
					<Col md={6} xs={12}>
						<FormGroup className="mb-3">
							<Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
								Image
							</Label>
							{editing || !props.selectedBlog ? (
								<Input
									id="img"
									className="form-control"
									type="file"
									accept="image/*"
									placeholder="Image"
									onChange={(e) => {
										props.setBlog({ ...props.blog, img: e.target.files[0] })
									}}
								/>
							) : (
								<div>
									<img
										src={props.selectedBlog.img}
										alt=""
										style={{ maxWidth: "20vw", maxHeight: "20vw" }}
									></img>
								</div>
							)}
						</FormGroup>
					</Col>
				</Row>

				{editing || !props.selectedBlog ? (
					<div className="col-12">
						{editing && (
							<button
								className="btn btn-secondary"
								style={{ marginRight: "1vw" }}
								onClick={(e) => {
									e.preventDefault()
									setEditing(false)
								}}
							>
								<i className="ion ion-md-close"></i>
							</button>
						)}
						<button
							className="btn btn-primary"
							type={loadingSubmit ? null : "submit"}
						>
							{loadingSubmit ? (
								<Spinner size={"sm"} color="secondary" />
							) : (
								"Submit"
							)}
						</button>
					</div>
				) : (
					<div className="col-12">
						<button
							className="btn btn-primary"
							style={{ marginRight: "1vw" }}
							onClick={(e) => {
								e.preventDefault()
								setEditing(true)
							}}
						>
							<i className="fas fa-edit"></i>
						</button>

						<button
							className="btn btn-danger"
							onClick={(e) => {
								e.preventDefault()
								handleDelete(props.selectedBlog._id)
							}}
						>
							<i className="fas fa-trash"></i>
						</button>
					</div>
				)}
			</Form>
		</>
	)
}
AddEditBlog.propTypes = {
	blog: PropTypes.any,
	selectedBlog: PropTypes.any,
	setBlog: PropTypes.any,
	setSelectedBlog: PropTypes.any,
	setReload: PropTypes.any,
}

export default AddEditBlog
