/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import {
    Form,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Input,
    Button,
} from "reactstrap"

import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logodarkImg from "../../assets/images/logo-dark.png"
import logosmImg from "../../assets/images/logo-sm.png"
import logolightImg from "../../assets/images/logo-light.png"
import logo from "../../assets/images/logo-4.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
} from "../../store/actions"

const Header = (props) => {
    const [search, setsearch] = useState(false)
    const [singlebtn, setSinglebtn] = useState(false)

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                )
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }

    function tToggle() {
        var body = document.body
        if (window.screen.width <= 992) {
            body.classList.toggle("sidebar-enable")
        } else {
            body.classList.toggle("vertical-collpsed")
            body.classList.toggle("sidebar-enable")
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link
                                to={
                                    sessionStorage.getItem("admin")
                                        ? "/dashboard"
                                        : "/products"
                                }
                                className="logo "
                            >
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="30" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" height="70" />
                                </span>
                            </Link>

                            {/* <Link to="/" className="logo logo-light">
								<span className="logo-sm">
									<img src={logosmImg} alt="" height="22" />
								</span>
								<span className="logo-lg">
									<img src={logolightImg} alt="" height="18" />
								</span>
							</Link> */}
                        </div>
                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                            id="vertical-menu-btn"
                            onClick={() => {
                                tToggle()
                            }}
                            data-target="#topnav-menu-content"
                        >
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </div>

                    <div className="d-flex">
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
        state.Layout
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(withTranslation()(Header))
